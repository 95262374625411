import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, linkedSignal, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CollapsePanelModule } from '@logic-suite/shared/components/collapse-panel';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { TranslateModule } from '@ngx-translate/core';
import { EnergyBlocksService } from '@suite/energy-blocks-data-access';
import { Favorite, FavoriteService } from '@suite/favorite';
import { HierarchyNode, HierarchyService } from '@suite/hierarchy-data-access';
import { RouterService } from '@suite/router';
import { ButtonModule } from 'primeng/button';
import { ButtonGroupModule } from 'primeng/buttongroup';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { TabMenuModule } from 'primeng/tabmenu';
import { TreeModule } from 'primeng/tree';
import { CollapsePanelComponent } from '@suite/collapse-panel';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { FavoriteHierarchyComponent, FullHierarchyComponent } from '@suite/hierarchy-ui';

type HierarchyType = 'full' | 'favorites' | 'search';

@Component({
  selector: 'lib-hierarchy',
  imports: [
    CommonModule,
    TreeModule,
    CardModule,
    ButtonModule,
    CdkTreeModule,
    TranslateModule,
    TabMenuModule,
    ButtonGroupModule,
    CollapsePanelModule,
    DividerModule,
    CollapsePanelComponent,
    SvgIconComponent,
    FullHierarchyComponent,
    FavoriteHierarchyComponent,
  ],
  template: `
    <lib-collapse-panel
      [visible]="hasHierarchy() && !isCollapsed()"
      [handleType]="hasHierarchy() ? 'icon' : 'none'"
      [initialWidth]="initialWidth"
      (widthChanged)="setWidthChanged($event)"
      (opened)="setOpened($event)"
      [minWidth]="140">
      <div class="bg-surface-0 dark:bg-surface-800 flex h-full w-full flex-col gap-1 px-4 pt-5">
        <div class="flex gap-1">
          <div
            class="hierarchy-tab-button"
            [ngClass]="{ active: selectedTab() === 'full' }"
            (click)="selectedTab.set('full')">
            <svg-icon class="min-h-5 min-w-5" size="sm" key="list"></svg-icon>
            <p class="text-sm">{{ 'Hierarchy' | translate }}</p>
          </div>

          <div
            class="hierarchy-tab-button"
            [ngClass]="{ active: selectedTab() === 'favorites' }"
            (click)="selectedTab.set('favorites')">
            <svg-icon class="min-h-5 min-w-5" size="sm" key="star"></svg-icon>
            <p class="text-sm">{{ 'Favorites' | translate }}</p>
          </div>
        </div>

        <p-divider></p-divider>

        @switch (selectedTab()) {
          @case ('full') {
            <lib-full-hierarchy
              [hierarchy]="hierarchy()"
              [favorites]="favorites()"
              [selectedNode]="selectedNode()"
              [energyBlocks]="energyBlocks()"
              [showConfigMenu]="showConfigMenu()"
              (selectNode)="selectNode($event)"
              (toggleFavorite)="toggleFavorite($event)"></lib-full-hierarchy>
          }
          @case ('favorites') {
            @if (favorites().size === 0) {
              <div class="text-surface-400 flex h-full flex-col items-center justify-center">
                <svg-icon class="text-surface-400" size="xxl" key="star"></svg-icon>
                <p class="text-center text-lg">{{ 'No favorites.' | translate }}</p>
                <p (click)="selectedTab.set('full')" class="cursor-pointer text-center text-sm hover:underline">
                  {{ 'Add favorites from the hierarchy.' | translate }}
                </p>
              </div>
            } @else {
              <lib-favorite-hierarchy
                [hierarchy]="hierarchy()"
                [favorites]="favorites()"
                [selectedNode]="selectedNode()"
                [energyBlocks]="energyBlocks()"
                [showConfigMenu]="showConfigMenu()"
                (selectNode)="selectNode($event)"
                (toggleFavorite)="toggleFavorite($event)"></lib-favorite-hierarchy>
            }
          }
        }
      </div>
    </lib-collapse-panel>
  `,
  styles: `
    .hierarchy-tab-button {
      @apply hover:bg-surface-100 dark:hover:bg-surface-700 flex flex-1 cursor-pointer items-center justify-center gap-2 rounded-lg px-3 py-2.5 transition-all duration-100;

      &.active {
        @apply bg-surface-100 dark:bg-surface-700;
      }
    }

    lib-full-hierarchy,
    lib-favorite-hierarchy {
      overflow-y: auto;
      margin: 0 -1rem;
      padding: 0 1rem;
    }

    .hierarchy-tab-button {
      container-type: inline-size;
      container-name: hierarchy-tab-button;
      @container (width < 100px) {
        .text-sm {
          display: none;
        }
      }
    }
  `,
})
export class HierarchyComponent implements OnInit {
  protected readonly router = inject(Router);
  protected readonly routerService = inject(RouterService);
  protected readonly favoriteService = inject(FavoriteService);
  protected readonly hierarchyService = inject(HierarchyService);
  protected readonly energyBlocksService = inject(EnergyBlocksService);
  protected readonly storage = inject(ApplicationStorageService);

  hierarchy = computed(() => this.hierarchyService.hierarchy());
  favorites = computed(() => this.favoriteService.favorites());
  selectedNode = computed(() => this.hierarchyService.selectedNode());
  energyBlocks = computed(() => this.energyBlocksService.energyBlocks());

  selectedTab = linkedSignal<HierarchyType>(() => this.storage.getItem('hierarchy.tab') || 'full');
  private selectedTabChange = effect(() => this.storage.setItem('hierarchy.tab', this.selectedTab()));

  initialWidth = Number(this.storage.getItem('hierarchy.width') || 320);
  isCollapsed = linkedSignal(() => this.storage.hasItem('hierarchy.collapsed'));

  showConfigMenu = computed(() => this.routerService.currentUrl().startsWith('/config'));
  // TODO(bjhandeland): Add this to route metadata.
  hasHierarchy = computed(
    () =>
      this.routerService.currentUrl().startsWith('/energy') ||
      this.routerService.currentUrl().startsWith('/config') ||
      this.routerService.currentUrl().startsWith('/sense') ||
      this.routerService.currentUrl().startsWith('/facility') ||
      this.routerService.currentUrl().startsWith('/advise') ||
      this.routerService.currentUrl().startsWith('/asset-log') ||
      this.routerService.currentUrl().startsWith('/co2-config') ||
      this.routerService.currentUrl().startsWith('/developer'),
  );

  ngOnInit() {
    if (this.favoriteService.favorites().size === 0) {
      this.selectedTab.set('full');
    }
  }

  toggleFavorite(hierarchyNode: HierarchyNode) {
    const favorite: Favorite = {
      id: hierarchyNode.getUniqueId(),
      type: hierarchyNode.type,
    };
    this.favoriteService.toggleFavorite$.next(favorite);
  }

  selectNode(hierarchyNode: HierarchyNode) {
    const queryParams = hierarchyNode.getQueryParams();
    this.router.navigate([], { queryParams });
  }

  setWidthChanged(width: number) {
    this.storage.setItem('hierarchy.width', width);
  }

  setOpened(opened: boolean) {
    if (opened) this.storage.removeItem('hierarchy.collapsed');
    else this.storage.setItem('hierarchy.collapsed', true);
    this.isCollapsed.set(!opened);

    // Reset initial width if the panel is collapsed
    if (!opened) {
      this.initialWidth = 320;
      this.storage.removeItem('hierarchy.width');
    }
  }
}
